'use client';

import React, { useEffect, useState } from 'react';

import Link from 'next/link';
import { useSearchParams } from 'next/navigation';

import { X } from 'lucide-react';

const Tournaments = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hiddenTournamentst, setHiddenTournamentst] = useState<string | null>(null);
  const [hiddenBtn, setHiddenBtn] = useState(false);
  const searchParams = useSearchParams();
  const provider = searchParams.get('provider');
  const gamecode = searchParams.get('gamecode');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHiddenTournamentst(localStorage.getItem('hiddenTournaments'));
    }
  }, [hiddenBtn]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    const currentTime = new Date().getTime();
    localStorage.setItem('hiddenTournaments', 'true');
    localStorage.setItem('hiddenTournamentsTimeout', (currentTime + 300000).toString());
    setHiddenBtn(true);
  };

  useEffect(() => {
    const currentTime = new Date().getTime();
    const hiddenTournamentsTimeouts = localStorage.getItem('hiddenTournamentsTimeout');

    if (hiddenTournamentsTimeouts && currentTime > Number(hiddenTournamentsTimeouts)) {
      localStorage.removeItem('hiddenTournaments');
      setHiddenBtn(false);
    }
  }, []);

  return (
    <div className={`fixed z-50 top-[50%] bottom-[50%] w-[211px] h-[111px] -mt-[55px] right-[5px] transition-all duration-300 ease-in-out bgTournamentsA pl-[70px] pr-[15px] flex justify-center items-center ${isOpen ? 'translate-x-0' : 'translate-x-full'} ${!provider && !gamecode ? 'block' : 'hidden'} ${hiddenTournamentst === 'true' ? 'hidden' : 'block'}`}>
      <div onClick={handleToggle} className="absolute -top-[3px] -left-[60px] bgTournamentsB animate-spin-on-load hover:animate-spin" />
      <X className="absolute -top-[7px] -left-[60px] text-slategray  cursor-pointer" onClick={handleClose} />
      <div className="font-medium text-sm text-center">
        <Link href="/casino/play?provider=onlyplay&gamecode=onlyplay%3APiggyBonanza&currency=HNL" className="w-[211px] h-[111px]">
          Únete al torneo Fruit Fest 🍒 🍉 ¿Listo para jugar y ganar?
        </Link>
      </div>
    </div>
  );
};

export default Tournaments;
